.ImageBaseOverlay {
  position: absolute;
  z-index: var(--vkui_internal--z_index_image_base_overlay);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: inherit;
  box-shadow: inherit;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ImageBaseOverlay--visible {
  opacity: 1;
}

.ImageBaseOverlay--focus-visible {
  opacity: 1;
  box-shadow: inset 0 0 0 2px var(--vkui--color_stroke_accent);
}

.ImageBaseOverlay--theme-light {
  color: var(--vkui--color_icon_accent);
  background-color: var(--vkui--color_avatar_overlay_inverse_alpha);
}

.ImageBaseOverlay--theme-dark {
  color: var(--vkui--color_icon_contrast);
  background-color: var(--vkui--color_avatar_overlay);
}
