.Cell {
  position: relative;
}

.List--dragging .Cell:not(.Cell--dragging) {
  transition: transform 0.3s ease;
  pointer-events: none;
}

.Cell--dragging {
  background-color: var(--vkui--color_background_secondary);
  box-shadow: var(--vkui--elevation3);
  z-index: var(--vkui_internal--z_index_cell_dragging);
}

.Cell--selectable.Cell--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Cell__checkbox,
.Cell__dragger {
  position: relative;
  flex-shrink: 0;
  margin-right: 16px;
}

.Cell__content {
  max-width: 100%;
  min-width: 0;
}

.Cell--removable .Cell__content {
  flex-grow: 1;
  padding-right: 0;
  padding-left: 0;
}

/**
 * iOS
 */
.Cell--ios .Cell__dragger {
  margin-right: 0;
  margin-left: 12px;
}

.Cell--ios .Cell__checkbox {
  margin-right: 12px;
}

/**
 * CMP:
 * FormItem
 */
.FormItem > .Cell {
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}
