.BonusModalPages {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    box-sizing: border-box;
}

.BonusModalPages > p {
    text-align: center;
    margin: 0 20px;
}