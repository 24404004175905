.WriteBarIcon {
  background: none;
  border: 0;
  position: relative;
  color: var(--vkui--color_icon_secondary);
  height: 52px;
  width: 44px;
  cursor: pointer;
  border-radius: var(--vkui--size_border_radius--regular);
}

.WriteBarIcon__in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.WriteBarIcon:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.WriteBarIcon__active {
  opacity: 0.6;
}

.WriteBarIcon__counter {
  position: absolute;
  top: 6px;
  right: 2px;
  box-shadow: 0 0 0 2px var(--vkui--color_background_modal);
}

.WriteBarIcon.WriteBarIcon--mode-send,
.WriteBarIcon.WriteBarIcon--mode-done {
  color: var(--vkui--color_icon_accent);
}

/**
 * iOS
 */
.WriteBarIcon--ios {
  color: var(--vkui--color_icon_accent);
}

.WriteBarIcon--ios.WriteBarIcon--mode-send,
.WriteBarIcon--ios.WriteBarIcon--mode-done {
  width: 48px;
  padding-left: 0;
  padding-right: 0;
}

.WriteBarIcon--ios.WriteBarIcon--mode-send:first-child,
.WriteBarIcon--ios.WriteBarIcon--mode-done:first-child {
  margin-left: -4px;
}

.WriteBarIcon--ios.WriteBarIcon--mode-send:last-child,
.WriteBarIcon--ios.WriteBarIcon--mode-done:last-child {
  margin-right: -4px;
}

.WriteBarIcon--mode-attach {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
