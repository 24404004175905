.Progress {
  border-radius: 1px;
  background: var(--vkui--color_track_background);
  height: 2px;
}

.Progress__in {
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease;
  background: var(--vkui--color_stroke_accent);
}
