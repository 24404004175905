.HorizontalCell {
  display: flex;
  font-family: var(--vkui--font_family_base);
}

.HorizontalCell__image {
  display: flex;
  justify-content: center;
}

.HorizontalCell__body {
  text-decoration: none;
  font-family: inherit;
}

.HorizontalCell__content {
  color: var(--vkui--color_text_primary);
  word-break: break-all;
  text-overflow: ellipsis;
}

.HorizontalCell__subtitle {
  color: var(--vkui--color_text_secondary);
}

.HorizontalCell__content .HorizontalCell__subtitle {
  margin-top: 2px;
}

.HorizontalCell--size-s {
  max-width: 80px;
}

.HorizontalCell__image {
  padding: 4px 6px;
}

.HorizontalCell__content {
  padding: 2px 6px 8px;
  text-align: left;
}

.HorizontalCell--size-s .HorizontalCell__image {
  padding: 4px 8px;
}

.HorizontalCell--size-s .HorizontalCell__content {
  padding: 2px 4px 8px;
  text-align: center;
}

.HorizontalCell--size-m {
  width: 100px;
}

.HorizontalCell--size-l {
  width: auto;
}

.HorizontalCell:first-child::before,
.HorizontalCell:last-child::after {
  content: '';
  min-width: 10px;
}

.HorizontalCell--size-s:first-child::before,
.HorizontalCell--size-s:last-child::after {
  min-width: 8px;
}

.HorizontalCell--size-s:first-child,
.HorizontalCell--size-s:last-child {
  max-width: 88px;
}

.HorizontalCell--size-m:first-child,
.HorizontalCell--size-m:last-child {
  width: 110px;
}

.HorizontalCell__title {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
