.Subhead {
  margin: 0;
  font-size: var(--vkui--font_subhead--font_size--regular);
  line-height: var(--vkui--font_subhead--line_height--regular);
  font-weight: var(--vkui--font_subhead--font_weight--regular);
  font-family: var(--vkui--font_subhead--font_family--regular);
}

.Subhead--sizeY-compact {
  font-size: var(--vkui--font_subhead--font_size--compact);
  line-height: var(--vkui--font_subhead--line_height--compact);
}

@media (--sizeY-compact) {
  .Subhead--sizeY-none {
    font-size: var(--vkui--font_subhead--font_size--compact);
    line-height: var(--vkui--font_subhead--line_height--compact);
  }
}

.Subhead--weight-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Subhead--weight-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Subhead--weight-3 {
  font-weight: var(--vkui--font_weight_accent3);
}
