.Slider {
  position: relative;
  height: 28px;
  padding: 0 14px;
}

.Slider::before {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--vkui--color_track_background);
  content: '';
  top: 13px;
  height: 2px;
  border-radius: 1px;
}

.Slider__in {
  height: 100%;
  position: relative;
}

.Slider__dragger {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  top: 13px;
}

.Slider__dragger::before {
  position: absolute;
  top: 0;
  width: calc(100% + 28px);
  background: var(--vkui--color_background_accent);
  content: '';
  left: -14px;
  height: 2px;
  border-radius: 1px;
}

.Slider__thumb {
  position: absolute;
  top: -13px;
  width: 28px;
  height: 28px;
  transition: transform 0.1s ease;
  border-radius: 14px;
  background: var(--vkui--color_background_contrast);
  box-shadow: var(--vkui--elevation3);
  border: 0.5px solid var(--vkui--color_separator_primary_alpha);
}

.Slider__thumb--start {
  left: -14px;
}

.Slider__thumb--end {
  right: -14px;
}

.Slider--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

/**
 * sizeY COMPACT
 */

.Slider--sizeY-compact {
  height: 24px;
  padding: 0 12px;
}

.Slider--sizeY-compact::before {
  top: 11px;
}

.Slider--sizeY-compact .Slider__dragger {
  top: 11px;
}

.Slider--sizeY-compact .Slider__dragger::before {
  width: calc(100% + 24px);
  left: -12px;
}

.Slider--sizeY-compact .Slider__thumb {
  top: -11px;
  width: 24px;
  height: 24px;
}

.Slider--sizeY-compact .Slider__thumb--start {
  left: -12px;
}

.Slider--sizeY-compact .Slider__thumb--end {
  right: -12px;
}

@media (--sizeY-compact) {
  .Slider--sizeY-none {
    height: 24px;
    padding: 0 12px;
  }

  .Slider--sizeY-none::before {
    top: 11px;
  }

  .Slider--sizeY-none .Slider__dragger {
    top: 11px;
  }

  .Slider--sizeY-none .Slider__dragger::before {
    width: calc(100% + 24px);
    left: -12px;
  }

  .Slider--sizeY-none .Slider__thumb {
    top: -11px;
    width: 24px;
    height: 24px;
  }

  .Slider--sizeY-none .Slider__thumb--start {
    left: -12px;
  }

  .Slider--sizeY-none .Slider__thumb--end {
    right: -12px;
  }
}
