.Gradient--mode-tint.Gradient--to-top {
  background: linear-gradient(to bottom, var(--vkui--gradient_tint));
}

.Gradient--mode-tint.Gradient--to-bottom {
  background: linear-gradient(to top, var(--vkui--gradient_tint));
}

.Gradient--mode-white.Gradient--to-top {
  background: linear-gradient(to bottom, var(--vkui--gradient_white));
}

.Gradient--mode-white.Gradient--to-bottom {
  background: linear-gradient(to top, var(--vkui--gradient_white));
}

.Gradient--mode-black.Gradient--to-top {
  background: linear-gradient(to bottom, var(--vkui--gradient_black));
}

.Gradient--mode-black.Gradient--to-bottom {
  background: linear-gradient(to top, var(--vkui--gradient_black));
}

.Gradient {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
