:root {
    --negative-color: rgb(206, 58, 58);
    --warn-color: rgb(241, 186, 104);
    --positive-color: rgb(51, 212, 51);
}

.Menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto auto;
}

.MenuBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    max-width: 300px;
}

.MenuBody > h1 {
    margin: 10px;
}

.MainMenu {
    padding: 10px;
}

.AuthMenu {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - var(--vkui--size_panel_header_height--regular) - 25px);
    box-sizing: border-box;
}

.AuthMenuInput {
    width: 100%;
    max-width: 400px;
}

.AuthMenuHeader {
    text-align: center;
    margin: 10px auto;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    opacity: 0.8;
}

.PostMenu {
    display: flex;
    align-items: center;
}

.PostMenuBody > h1 {
    font-size: 14px;
    opacity: 0.8;
}

.PostMenuBody > p {
    font-size: 12px;
    opacity: 0.8;
}

.PostMenu > img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.CalculateResultLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 10px 30px 10px;
}

.CalculateResultLoading > div {
    animation: rotate 2s infinite;
}

.CalculateResultLoading > p {
    text-transform: uppercase;
    opacity: 0.7;
}

@keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }

.warn {
  color: var(--warn-color);
}
.ResultPollAnswer > h1 {
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
}
a {
  color: blue;
  text-decoration: none; /* no underline */
}

.number {
    width: 20px;
}

.BannerImg {
    width: 100%;
    border-radius: 8px 8px 0 0;
    height: 100%;
    aspect-ratio: 1.7/1;
}

.InfoText {
    opacity: 0.8;
    font-size: 16px;
}

.UsersHeader {
    position: relative;
    background-color: #2b5885;
    color: #7dbbf9;
    min-height: 60px;
    align-items: center;
    border-radius: 8px 8px 0 0;
}

.UsersHeaderBody {
    background-color: #4a76a8;
}

.UsersHeader > h1 {
    color: white;
    position: absolute;
    bottom: 0;
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    text-transform: uppercase;
}

.UsersHeaderCrown {
    position: absolute;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.UsersFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2b5885;
    color: rgb(255, 255, 255);
    min-height: 60px;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
}

.UsersFooter > p {
    margin: 0;
    opacity: 0.8;
    text-align: center;
    font-size: 12px;
}

.Separator {
    height: 40px;
}

.HeaderTextBottom {
    color: white;
}

.HeaderText {
    color: rgb(212, 212, 212);
}

.ButtonsMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}