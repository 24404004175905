.PopoutRoot {
  width: 100%;
  height: 100%;
  position: relative;
}

.PopoutRoot__popout {
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--vkui--z_index_popout);
  width: 100%;
  height: 100%;
}

.PopoutRoot__popout--absolute {
  position: absolute;
}

.PopoutRoot__modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--vkui--z_index_modal);
  width: 100%;
  height: 100%;
}

.PopoutRoot__modal:empty {
  display: none;
}
