.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Placeholder--stretched {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: inherit;
  padding-bottom: inherit;
}

.Placeholder__in {
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 48px 32px;
}

.Placeholder__icon {
  margin-bottom: 12px;
  display: inline-block;
  vertical-align: top;
  color: var(--vkui--color_icon_secondary);
}

.Placeholder__header {
  color: var(--vkui--color_text_primary);
}

.Placeholder__text {
  color: var(--vkui--color_text_secondary);
}

.Placeholder__header + .Placeholder__text {
  margin-top: 8px;
}

.Placeholder__action:not(:first-child) {
  margin-top: 16px;
}
