.ImageBase {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  color: var(--vkui--color_icon_secondary);
  background-color: var(--vkui--color_background_secondary);
  background-size: cover;
}

.ImageBase--withBorder {
  box-shadow: inset 0 0 0 var(--vkui_internal--thin_border) var(--vkui--color_image_border_alpha);
}

.ImageBase__img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: inherit;
  object-fit: cover;
  visibility: hidden;
}

.ImageBase--loaded .ImageBase__img {
  visibility: visible;
}

.ImageBase__fallback {
  position: absolute;

  /* Расчитываем на ценитрирование через родительский `display: flex` */
  top: auto;
  left: auto;
}

.ImageBase--size-16,
.ImageBase--size-20,
.ImageBase--size-24,
.ImageBase--size-28,
.ImageBase--size-32,
.ImageBase--size-36,
.ImageBase--size-40,
.ImageBase--size-44,
.ImageBase--size-48,
.ImageBase--size-56,
.ImageBase--size-64,
.ImageBase--size-72,
.ImageBase--size-80,
.ImageBase--size-88,
.ImageBase--size-96 {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md) */
}

/**
 * CMP:
 * CellButton
 */
.CellButton > .ImageBase :global(.vkuiIcon) {
  color: var(--vkui--color_icon_accent);
}

.CellButton--mode-danger > .ImageBase :global(.vkuiIcon) {
  color: var(--vkui--color_icon_negative);
}

/**
 * CMP:
 * PanelHeader
 */
.PanelHeader__before .ImageBase {
  margin-left: 8px;
}

.PanelHeader__after .ImageBase {
  margin-right: 8px;
}

/**
 * CMP:
 * SimpleCell
 */
.SimpleCell > .ImageBase {
  margin-right: 12px;
}

.SimpleCell > .ImageBase--size-28,
.SimpleCell > .ImageBase--size-32 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.SimpleCell > .ImageBase--size-40 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.SimpleCell > .ImageBase--size-48,
.SimpleCell > .ImageBase--size-72 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.SimpleCell--ios > .ImageBase--size-28,
.SimpleCell--ios > .ImageBase--size-32 {
  margin-left: 4px;
}

.SimpleCell--sizeY-compact > .ImageBase--size-28,
.SimpleCell--sizeY-compact > .ImageBase--size-32 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.SimpleCell--sizeY-compact > .ImageBase--size-40 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.SimpleCell--sizeY-compact > .ImageBase--size-48 {
  margin-top: 4px;
  margin-bottom: 4px;
}

@media (--sizeY-compact) {
  .SimpleCell--sizeY-none > .ImageBase--size-28,
  .SimpleCell--sizeY-none > .ImageBase--size-32 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .SimpleCell--sizeY-none > .ImageBase--size-40 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .SimpleCell--sizeY-none > .ImageBase--size-48 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
