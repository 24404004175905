.Pagination {
}

.Pagination__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Pagination__prevButtonContainer {
  margin-right: 8px;
}

.Pagination__nextButtonContainer {
  margin-left: 8px;
}

/* Page styles */
.Pagination__page {
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  min-width: 40px;
  border-radius: var(--vkui--size_border_radius--regular);
  text-align: center;
  color: var(--vkui--color_text_secondary);
  user-select: none;
  font-family: var(--vkui--font_family_base);
}

.Pagination__page--state-hover {
  background-color: var(--vkui--color_transparent--hover);
}

.Pagination__page--state-active,
.Pagination__page--current {
  color: var(--vkui--color_text_primary);
  background-color: var(--vkui--color_transparent--active);
}

.Pagination__page--disabled {
  opacity: var(--vkui--opacity_disable);
}

.Pagination__page--sizeY-compact {
  font-size: var(--vkui--font_text--font_size--compact);
  line-height: var(--vkui--size_button_large_height--compact);
  height: var(--vkui--size_button_large_height--compact);
}

@media (--sizeY-compact) {
  .Pagination__page--sizeY-none {
    font-size: var(--vkui--font_text--font_size--compact);
    line-height: var(--vkui--size_button_large_height--compact);
    height: var(--vkui--size_button_large_height--compact);
  }
}

.Pagination__page--sizeY-regular {
  font-size: var(--vkui--font_text--font_size--regular);
  line-height: var(--vkui--size_button_large_height--regular);
  height: var(--vkui--size_button_large_height--regular);
}

@media (--sizeY-regular) {
  .Pagination__page--sizeY-none {
    font-size: var(--vkui--font_text--font_size--regular);
    line-height: var(--vkui--size_button_large_height--regular);
    height: var(--vkui--size_button_large_height--regular);
  }
}

.Pagination__page--type-ellipsis {
  min-width: 16px;
  padding-right: 0;
  padding-left: 0;
}
