.ActionSheetItem {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--vkui--color_text_primary);
  padding: 0 20px;
  min-height: 48px;
  border-radius: 0;
  box-sizing: border-box;
}

.ActionSheetItem__container,
.ActionSheetItem__before,
.ActionSheetItem__after {
  position: relative;
}

.ActionSheetItem__before,
.ActionSheetItem__after {
  flex-shrink: 0;
}

.ActionSheetItem__before {
  margin-right: 18px;
  margin-left: -2px;
}

.ActionSheetItem__container {
  max-width: 100%;
  flex-grow: 1;
  padding: 10px 0;
}

.ActionSheetItem--ellipsis {
  overflow: hidden;
  white-space: nowrap;
}

.ActionSheetItem__content {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  justify-content: space-between;
}

.ActionSheetItem--ellipsis .ActionSheetItem__content {
  justify-content: flex-start;
}

.ActionSheetItem__children {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ActionSheetItem__subtitle {
  color: var(--vkui--color_text_secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}

.ActionSheetItem__meta {
  color: var(--vkui--color_text_secondary);
  margin-left: 6px;
  flex-shrink: 0;
}

.ActionSheetItem__before {
  color: var(--vkui--color_icon_accent);
}

.ActionSheetItem--mode-destructive .ActionSheetItem__container:first-child {
  color: var(--vkui--color_text_negative);
}

.ActionSheetItem--mode-destructive .ActionSheetItem__before {
  color: var(--vkui--color_icon_negative);
}

.ActionSheetItem__radio {
  display: none;
}

.ActionSheetItem__marker {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--vkui--color_icon_accent);
  margin-left: 16px;
}

.ActionSheetItem__radio:checked ~ .ActionSheetItem__marker {
  display: flex;
}

/**
 * iOS
 */

.ActionSheetItem--ios {
  padding: 14px 20px 14px 19px;
  min-height: 56px;
  border-radius: 0;
  color: var(--vkui--color_text_accent);
  background: var(--vkui--color_background_modal);
}

.ActionSheetItem--rich {
  color: var(--vkui--color_text_primary);
}

.ActionSheetItem--ios.ActionSheetItem--mode-destructive {
  color: var(--vkui--color_text_negative);
}

.ActionSheetItem--ios:first-child {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.ActionSheetItem--ios:not(.ActionSheetItem--desktop):nth-last-child(2),
.ActionSheetItem--ios:last-child {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.ActionSheetItem--ios::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--vkui--color_background_modal);
  content: '';
  transition: opacity 0.15s ease-out, background-color 0.15s ease-out;
}

.ActionSheetItem--ios::after {
  position: absolute;
  right: 0;
  left: 0;
  height: 1px;
  content: '';
  top: 0;
  background: var(--vkui--color_separator_primary);
  transform-origin: center bottom;
}

@media (min-resolution: 2dppx) {
  .ActionSheetItem--ios::after {
    transform: scaleY(0.5);
  }
}

@media (min-resolution: 3dppx) {
  .ActionSheetItem--ios::after {
    transform: scaleY(0.33);
  }
}

.ActionSheetItem--ios:first-child::after,
.ActionSheetItem--mode-cancel::after,
.ActionSheet--ios .ActionSheetItem--last::after,
.ActionSheet--ios .ActionSheet__header:empty {
  content: none;
}

.ActionSheetItem--ios.ActionSheetItem--active::before {
  background-color: var(--vkui--color_transparent--active);
  opacity: 1;
  transition: none;
}

.ActionSheetItem--ios .ActionSheetItem__container {
  padding: 0;
}

.ActionSheetItem--ios .ActionSheetItem--centered {
  justify-content: center;
}

.ActionSheetItem--mode-cancel {
  margin-top: 8px;
  margin-bottom: var(--vkui_internal--safe_area_inset_bottom);
  border-radius: 14px;
  min-height: 52px;
}

/**
 * Desktop
 */

.ActionSheetItem--desktop {
  width: auto;
  cursor: pointer;
}

/**
 * Disabled
 */

.ActionSheetItem[disabled] .ActionSheetItem__children,
.ActionSheetItem[disabled] .ActionSheetItem__meta,
.ActionSheetItem[disabled] .ActionSheetItem__subtitle {
  color: var(--vkui--color_text_secondary);
}

.ActionSheetItem[disabled] .ActionSheetItem__marker,
.ActionSheetItem[disabled] .ActionSheetItem__before {
  color: var(--vkui--color_icon_secondary);
}

/**
 * SizeY = compact
 */

.ActionSheetItem--sizeY-compact {
  min-height: 32px;
}

.ActionSheetItem--sizeY-compact.ActionSheetItem--ios {
  min-height: 36px;
  padding: 4px 20px 4px 18px;
}

.ActionSheetItem--sizeY-compact:not(.ActionSheetItem--ios)
  + .ActionSheetItem--sizeY-compact:not(.ActionSheetItem--ios) {
  margin-top: 2px;
}

.ActionSheetItem--sizeY-compact .ActionSheetItem__container {
  padding: 5px 0;
}

.ActionSheetItem--mode-default {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
