/**
 * ⚠ WARNING️ ⚠
 *
 * Если указать `position: relaitve` контейнеру, где позиционируется `Popper`, то при кейсе когда:
 * 1. Мы передали `sameWidth`.
 * 2. Мы позциционируем `Popper` через `position: absolute`.
 * 3. Мы используем `height: 100%` или `height: 100vh`, чтобы растянуть страницу на всю высоту.
 * 4. НЕТ скроллбара.
 * будет неправильно высчитан `offsetWidth` у `targetRef` при первом вызове `effect()` модификатора `sameWdith`,
 *  а именно из `offsetWidth` будет вычтен размер скроллабара, которого на самом деле на странице нет (см. п. 4)
 *
 * Второй и последующие вызовы исправляют ситуацию.
 */
.Popper {
  z-index: var(--vkui--z_index_popout);
}

.Popper__content,
.PopperPortal {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
