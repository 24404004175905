.PanelHeaderBack--vkcom.PanelHeaderBack--has-label {
  padding-right: 11px;
}

@media (--sizeX-regular) {
  .PanelHeaderBack--ios.PanelHeaderBack--has-label:not(.PanelHeaderBack--sizeX-compact) {
    padding-right: 8px;
  }
}

.PanelHeaderBack {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
