.Title {
  display: block;
  margin: 0;
}

.Title--level-1 {
  font-size: var(--vkui--font_title1--font_size--regular);
  line-height: var(--vkui--font_title1--line_height--regular);
  font-weight: var(--vkui--font_title1--font_weight--regular);
  font-family: var(--vkui--font_title1--font_family--regular);
}

.Title--level-2 {
  font-size: var(--vkui--font_title2--font_size--regular);
  line-height: var(--vkui--font_title2--line_height--regular);
  font-weight: var(--vkui--font_title2--font_weight--regular);
  font-family: var(--vkui--font_title2--font_family--regular);
}

.Title--level-3 {
  font-size: var(--vkui--font_title3--font_size--regular);
  line-height: var(--vkui--font_title3--line_height--regular);
  font-weight: var(--vkui--font_title3--font_weight--regular);
  font-family: var(--vkui--font_title3--font_family--regular);
}

.Title--weight-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Title--weight-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Title--weight-3 {
  font-weight: var(--vkui--font_weight_accent3);
}
