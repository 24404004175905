.ContentCard--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.ContentCard__body {
  padding: var(--vkui--size_base_padding_vertical--regular)
    var(--vkui--size_base_padding_horizontal--regular);
}

.ContentCard__tappable {
  border-radius: var(--vkui--size_card_border_radius--regular);
  text-decoration: none;
  color: var(--vkui--color_text_primary);
  display: block;
}

.ContentCard__img {
  object-fit: cover;
  display: block;
  border-radius: var(--vkui--size_card_border_radius--regular)
    var(--vkui--size_card_border_radius--regular) 0 0;
}

.ContentCard__text:not(:last-child) {
  margin-bottom: 4px;
}

.ContentCard__caption,
.ContentCard__subtitle {
  color: var(--vkui--color_text_secondary);
}

.ContentCard {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
