.Tooltip {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: normal;
}

.Tooltip__container {
  position: absolute;
}

.Tooltip__content {
  background-color: var(--vkui--color_background_modal);
  box-shadow: var(--vkui--elevation3);
  padding: 9px 12px 10px;
  border-radius: var(--vkui--size_border_radius--regular);
  color: var(--vkui--color_text_primary);
  max-width: 220px;
}

.Tooltip__arrow {
  color: var(--vkui--color_background_modal);
}

.Tooltip--appearance-accent .Tooltip__content {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.Tooltip--appearance-accent .Tooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.Tooltip--appearance-white .Tooltip__content {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.Tooltip--appearance-white .Tooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.Tooltip--appearance-black .Tooltip__content {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.Tooltip--appearance-black .Tooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.Tooltip--appearance-inversion .Tooltip__content {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.Tooltip--appearance-inversion .Tooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}

.Tooltip__title,
.Tooltip__text,
.Tooltip--appearance-neutral {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
