.HomeHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomeHeader > h1 {
    margin: 10px;
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
}

.HomeHeader > p {
    margin: 0;
    margin-bottom: 10px;
}

.HomeHeader > span {
    opacity: 0.8;
    font-size: 13px;
    margin-bottom: 10px;
}