.CustomSelect {
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
}

.CustomSelect--pop-down {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.CustomSelect--pop-up {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.CustomSelect__control {
  display: none;
}

.CustomSelect__empty {
  padding: 12px 0;
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.CustomSelect__fetching {
  display: flex;
  align-items: center;
  justify-content: center;
}
