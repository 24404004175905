.Calendar {
  display: flex;
  flex-direction: column;
  background: var(--vkui--color_background_modal);
  border: 0.5px solid var(--vkui--color_separator_primary);
  box-sizing: border-box;
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
  width: 305px;
  padding: 12px;
}

.Calendar__time {
  border-top: 1px solid var(--vkui--color_separator_primary);
  margin: 12px -12px 0;
  padding: 12px 12px 0;
}

.Calendar__header {
  margin-bottom: 4px;
}

.Calendar--size-s {
  width: 220px;
  padding: 8px;
}

.Calendar--size-s .Calendar__header {
  margin-bottom: 0;
}

.Calendar--size-m {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
