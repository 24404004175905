.SplitCol {
  height: 100%;
  width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.SplitCol--spaced {
  margin: 0 var(--vkui--size_split_col_padding_horizontal--regular);
}

.SplitCol--viewWidth-tabletPlus.SplitCol--spaced-none.SplitCol--spaced-auto {
  margin: 0 var(--vkui--size_split_col_padding_horizontal--regular);
}

@media (--viewWidth-smallTabletPlus) {
  .SplitCol--viewWidth-none.SplitCol--spaced-none.SplitCol--spaced-auto {
    margin: 0 var(--vkui--size_split_col_padding_horizontal--regular);
  }
}

.SplitCol--viewWidth-smallTablet.SplitCol--stretched-on-mobile,
.SplitCol--viewWidth-mobile.SplitCol--stretched-on-mobile,
.SplitCol--viewWidth-smallMobile.SplitCol--stretched-on-mobile {
  width: 100% !important;
  max-width: 100% !important;
}

@media (--viewWidth-tabletMinus) {
  .SplitCol--viewWidth-none.SplitCol--stretched-on-mobile {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.SplitCol--fixed {
  height: 100%;
  width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
}

.SplitCol__fixedInner {
  height: inherit;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  position: fixed;
  transform: translateZ(0);
  overflow: hidden;
  top: 0;
}
