.PanelHeaderContent {
  line-height: initial;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--vkui--font_family_base);
}

.PanelHeaderContent__before {
  flex-shrink: 0;
  margin-right: 12px;
}

.PanelHeaderContent__in {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.PanelHeaderContent__status,
.PanelHeaderContent__children-in {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PanelHeaderContent__status {
  order: 1;
  margin-top: 1px;
  max-width: 100%;
  color: var(--vkui--color_text_secondary);
}

.PanelHeaderContent__children {
  max-width: 100%;
  display: flex;
  align-items: center;
}

.PanelHeaderContent__children-in {
  flex-grow: 0;
  min-width: 0;
}

.PanelHeaderContent__aside {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
  color: var(--vkui--color_icon_accent);
}

.PanelHeaderContent__width {
  width: 1000px;
}

.PanelHeaderContent__in--centered {
  align-items: center;
}

/**
 * iOS
 */

.PanelHeaderContent--ios .PanelHeaderContent__children-in {
  font-size: 21px;
  font-family: var(--vkui--font_family_accent);
  font-weight: 500;
  line-height: var(--vkui--size_panel_header_height--regular);
}

/**
 * Android
 */

.PanelHeaderContent--android .PanelHeaderContent__children-in {
  font-size: 23px;
  font-family: var(--vkui--font_family_accent);
  font-weight: 500;
  line-height: var(--vkui--size_panel_header_height--regular);
}

/**
 * VKCOM
 */
.PanelHeader--no-before .PanelHeaderContent--vkcom {
  padding-left: 16px;
}
