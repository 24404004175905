.RichTooltip {
  box-shadow: var(--vkui--elevation3);
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(--vkui--color_background_modal);
  animation: vkui-popper-fadein 0.2s ease;
  color: var(--vkui--color_text_primary);
}

.RichTooltip__arrow {
  color: var(--vkui--color_background_modal);
}

.RichTooltip--appearance-accent {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.RichTooltip--appearance-accent .RichTooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.RichTooltip--appearance-white {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.RichTooltip--appearance-white .RichTooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.RichTooltip--appearance-black {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.RichTooltip--appearance-black .RichTooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.RichTooltip--appearance-inversion {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.RichTooltip--appearance-inversion .RichTooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}

.RichTooltip--appearance-neutral {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}
